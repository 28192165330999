.navbar-brand {
  line-height: 14px;
  font-size: 15px;
  padding-top: 20px;
  padding-bottom: 5px;
  font-weight: bold; }

.navbar-brand small {
  display: block;
  font-weight: lighter;
  margin-top: 2px;
  font-size: 13px; }

.BannerModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100; }
  .BannerModal > div {
    position: fixed;
    top: 0;
    left: -200px;
    margin-left: 50%;
    margin-top: 10vh;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    width: 500px;
    max-width: 80vw;
    max-height: 80vh;
    z-index: 10; }
  .BannerModal img {
    z-index: 100; }
  .BannerModal .Loader {
    position: absolute;
    top: 0;
    max-width: 460px;
    width: 500px;
    z-index: 50; }
  @media screen and (max-width: 600px) {
    .BannerModal > div {
      width: 80vw;
      left: -40vw; }
    .BannerModal .Loader {
      max-width: 80vw;
      width: 80vw; } }

.ProductChoiceForm .ProductQuadBox:hover {
  border-color: #f77878;
  cursor: pointer; }

.OrderRowCreationFormProductSelectForm label {
  color: BLACK; }

.OrderRowCreationFormProductSelectForm input {
  border: 2px solid grey; }

.OptionGenericSelectForm_AutoCompleteError label {
  color: red; }

.OptionGenericSelectForm_AutoCompleteError input {
  border: 1px solid red; }

.OrderC_AutoCompleteContainer {
  position: relative; }
  .OrderC_AutoCompleteContainer .OrderC_AutoComplete {
    position: absolute;
    min-height: 1.5em;
    z-index: 6666666;
    background-color: white;
    border: 1px solid #d4d3d3;
    margin-top: 3px;
    max-height: 40vh;
    overflow-y: scroll;
    min-width: 20vw;
    right: 15px; }
    .OrderC_AutoCompleteContainer .OrderC_AutoComplete .OrderC_AutoCompleteItems {
      padding: 10px;
      cursor: pointer; }
      .OrderC_AutoCompleteContainer .OrderC_AutoComplete .OrderC_AutoCompleteItems:hover {
        background-color: lightgray; }
    .OrderC_AutoCompleteContainer .OrderC_AutoComplete .OrderC_AutoCompleteItemsSelected {
      background-color: #5d0000;
      color: white; }
  .OrderC_AutoCompleteContainer .OrderC_AutoCompleteContainerIcon {
    width: 30px;
    height: 28px;
    position: absolute;
    right: 20px;
    top: 6px;
    font-size: 13pt;
    text-align: center;
    color: lightgray; }

@media only screen and (max-width: 992px) {
  .OrderRowCreationProductsChooser .CategoryName.col-md-1 {
    width: 100% !important; }
  .OrderRowCreationProductsChooser .CategoryName.col-md-3 {
    width: 100% !important; } }

.OrderRowCreationProductsChooser .m-t {
  margin: 5px; }

.OrderRowCreationProductsChooser .CategoryName span {
  background-color: red;
  display: block;
  color: white;
  padding: 10px;
  text-align: center;
  margin-bottom: 10px; }

.OrderRowCreationProductsChooser .CategoryName.col-md-1 {
  width: 12.5%; }

.OrderRowCreationProductsChooser .CategoryName.col-md-3 {
  width: 36%; }

.OrderRowCreationProductsChooser .product-box {
  cursor: pointer;
  padding: 10px 15px; }

.OrderRowCreationProductsChooser .SameOrder .product-box {
  border: 1px solid #c37878; }

.OrderRowCreationProductsChooser .SameOrder span {
  display: none; }

.OrderRowCreationProductsChooser .SameOrder:hover .fa {
  display: none; }

.OrderRowCreationProductsChooser .SameOrder:hover span {
  display: inline; }

.OrderRowCreationProductsChooser .SameOrder .fa, .OrderRowCreationProductsChooser .SameOrder span {
  /* margin: -5px; */
  position: absolute;
  top: 10px;
  right: 25px; }

.OrderRowCreationForm form.form-inline .form-group {
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 20px; }

.OrderRowCreationForm .ProdcutChooser .form-group {
  width: 40%;
  margin-left: 30% !important;
  min-width: 400px; }

.OrderRowCreationForm .OrderRowCF_Checks {
  width: 150pt; }
  .OrderRowCreationForm .OrderRowCF_Checks .control-label {
    margin-bottom: 5px; }
  .OrderRowCreationForm .OrderRowCF_Checks .i-checks {
    margin: 5px;
    margin-top: 10px;
    display: inline; }
    .OrderRowCreationForm .OrderRowCF_Checks .i-checks + .i-checks {
      margin-left: 15px; }

.OptionGenericSelectForm_AutoCompleteHeader {
  position: relative;
  top: -10px;
  background-color: #d0d0d0;
  min-height: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  /* border-bottom: 1px solid #525252; */
  color: black;
  font-size: 8pt; }

.InlineChecks .i-checks {
  float: left;
  margin: 10px; }

.InlineChecks:after {
  display: block;
  content: ' ';
  clear: both; }

.OptionColorForm > div {
  position: relative; }

.OptionColorForm input {
  width: 230px; }

.OptionColorFormSingleColor {
  margin-bottom: 8px;
  cursor: pointer; }
  .OptionColorFormSingleColor .OptionColorFormSingleColor_BigBox {
    min-height: 90px;
    min-width: 150px;
    max-width: 150px;
    background-color: lightgray;
    border-radius: 5px;
    border-width: 1px;
    background-image: url("colors.jpg");
    background-position: 0.9% 7.3%; }
    .OptionColorFormSingleColor .OptionColorFormSingleColor_BigBox:hover {
      border-color: black; }

#shipping_address .ShippingAddress {
  min-height: auto; }
  #shipping_address .ShippingAddress .product-box {
    min-height: 16em; }

.UserEdit_ContactsRow span {
  font-weight: 700;
  margin-top: 10px;
  font-size: 8pt; }

.Loader + .Loader, .NoItemFound + .NoItemFound {
  display: none; }

.NoItemFound {
  text-align: 'center';
  font-style: 'italic';
  padding: '20px'; }

.navbar-header p {
  padding-top: 17px;
  float: left;
  font-weight: bold;
  font-size: 15px;
  padding-left: 20px; }

.RecoverPasswordMsg {
  font-size: 14pt;
  position: relative;
  left: -50%;
  width: 200%; }

@media screen and (max-width: 800px) {
  .RecoverPasswordMsg {
    width: 140%;
    left: -20%; } }

.big-login-container {
  width: 100%; }
  .big-login-container .LoginInfoBox {
    width: 100%; }
  .big-login-container .login-splash {
    display: none;
    background: linear-gradient(305deg, #d2d2d2, #aeaeae, #d5d5d5);
    background-size: 600% 600%;
    -webkit-animation: AnimationName 3s ease infinite;
    -moz-animation: AnimationName 3s ease infinite;
    animation: AnimationName 3s ease infinite; }

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 10%; }
  50% {
    background-position: 100% 91%; }
  100% {
    background-position: 0% 10%; } }

@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 10%; }
  50% {
    background-position: 100% 91%; }
  100% {
    background-position: 0% 10%; } }

@keyframes AnimationName {
  0% {
    background-position: 0% 10%; }
  50% {
    background-position: 100% 91%; }
  100% {
    background-position: 0% 10%; } }

@media screen and (min-width: 1020px) {
  .big-login-container {
    width: 50%; }
    .big-login-container .LoginInfoBox {
      width: 50%; }
    .big-login-container .login-splash {
      display: block;
      position: fixed;
      right: 0;
      top: 0;
      width: 50%;
      height: 100vh; } }

.Login label {
  text-align: left;
  display: block;
  font-weight: normal; }

.Login.loginscreen.middle-box {
  width: 500px;
  /*margin-top: 200px;*/
  margin-top: 10vh; }

.Login form {
  background-color: white;
  padding: 30px;
  font-size: 16pt;
  box-shadow: 10px 10px 10px #a5a5a58a;
  margin-bottom: 20px; }
  .Login form h2 {
    text-align: left;
    font-weight: bold; }
  .Login form p.m-t {
    text-align: right;
    font-size: 12pt;
    margin: 0;
    padding: 0; }

.Login .form-control, .Login .btn {
  font-size: 16pt;
  margin-bottom: 40px;
  height: 52px;
  padding: 12px; }

.Login .LogoContainer {
  width: 100%;
  height: 115px;
  text-align: center; }
  .Login .LogoContainer svg {
    height: 110px; }

@media screen and (max-width: 600px) {
  .Login svg {
    width: 70%; }
  .Login .LoginInfoBox {
    height: 8em; }
  .Login .form-control, .Login .btn {
    font-size: 14pt;
    margin-bottom: 20px; }
  .Login form {
    font-size: 14pt; }
    .Login form p.mt {
      margin-top: 0 !important; }
  .Login.loginscreen.middle-box {
    width: 340px;
    margin-top: 60px; } }

.LoginInfoBox {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  text-align: left;
  height: 6em;
  padding: 20px; }
  .LoginInfoBox a {
    color: white;
    text-decoration: underline; }
  .LoginInfoBox .fa {
    margin-left: 10px; }

#Stats .ct-series-a .ct-bar {
  /* Set the colour of this series line */
  stroke: gray;
  /* Control the thikness of your lines */
  stroke-width: 20px; }

#Stats .ct-series-b .ct-bar {
  /* Set the colour of this series line */
  stroke: #e63535;
  /* Control the thikness of your lines */
  stroke-width: 20px; }

#Stats .ct-label {
  color: black;
  font-size: 11pt; }

#Stats table tr:last-child {
  font-weight: bold; }

.DocumentList tr .btn + .btn {
  margin-left: 5px; }

@media (min-width: 768px) {
  .OrdersListByMonthFilter {
    width: auto; } }

@media (max-width: 1650px) {
  .visible-xl {
    display: none; } }

@media (min-width: 1650px) {
  .hidden-xl {
    display: none; } }

.search-form-centered {
  margin-top: 15px;
  width: 50%;
  margin-left: 25%; }
  @media (max-width: 1022px) {
    .search-form-centered {
      width: 94%;
      margin-left: 3%; }
      .search-form-centered .navbar-form-custom {
        display: inline; }
      .search-form-centered .form-group {
        width: 74%; } }
  .search-form-centered .form-group {
    background-color: white;
    border-top: 3px solid #e7eaec;
    border-bottom: 1px solid #e7eaec; }
  .search-form-centered i {
    float: left;
    font-size: 14pt;
    color: #e7eaec;
    margin: 10px; }
  .search-form-centered form {
    width: 110%;
    height: 30pt; }
  .search-form-centered input.form-control {
    height: 28pt; }
  .search-form-centered div {
    float: left;
    width: 80%;
    margin-right: 20px; }
  .search-form-centered input {
    width: 80%; }
  .search-form-centered button {
    margin-top: 4px; }

.DasboardButtons .btn {
  margin-right: 5px;
  margin-bottom: 10px; }

.file-box .file-name {
  color: #a87171; }

.SweetAlert .SweetAlertBg {
  z-index: 10000;
  background-color: black;
  opacity: 0.5;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0; }

.SweetAlert .SweetAlertBox {
  text-align: center;
  background-color: white;
  width: 80vw;
  border-radius: 6px;
  margin-left: 10vw;
  margin-top: 40vh;
  padding: 25px;
  opacity: 1;
  position: fixed;
  z-index: 10001; }
  .SweetAlert .SweetAlertBox h3 {
    font-size: 24pt; }
  .SweetAlert .SweetAlertBox p {
    margin: 30px 0; }
  .SweetAlert .SweetAlertBox .btn + .btn {
    margin-left: 10px; }

@media screen and (min-width: 800px) {
  .SweetAlert .SweetAlertBox {
    width: 600px;
    margin-left: -300px;
    left: 50%; } }

.FormInputImage {
  position: relative;
  max-width: 100%;
  cursor: pointer;
  overflow: hidden; }
  .FormInputImage img {
    width: 100%; }
  .FormInputImage:hover .FormInputImageUploadIcon {
    display: block; }
  .FormInputImage .FormInputImageUploadIcon {
    display: none;
    position: absolute;
    background-color: black;
    width: 100%;
    top: 0;
    height: 100%;
    opacity: 0.7; }
    .FormInputImage .FormInputImageUploadIcon .fa {
      width: 100%;
      font-size: 30px;
      position: relative;
      top: 50%;
      left: 0;
      margin-top: -15px;
      font-weight: bold;
      color: #adadad;
      display: block;
      text-align: center; }

.OrderView dd, .OrderView dt {
  margin-bottom: 3px; }

.OrderView .OrderViewRow.feed-element {
  margin-top: 0;
  padding-bottom: 0; }

.OrderView .OrderViewActions button + a, .OrderView .OrderViewActions a + button, .OrderView .OrderViewActions button + button, .OrderView .OrderViewActions a + a {
  margin-left: 10px; }

.OrderView .OrderViewActions button, .OrderView .OrderViewActions a {
  float: left;
  margin-bottom: 10px; }

.OrderView .OrderViewActions::after {
  content: ' ';
  display: block;
  clear: both; }

.OrderView table tr td h4 small {
  margin-left: 15px; }

.OrderView td, .OrderView th {
  text-align: right; }

.FormInputImagePlaceholder {
  background-color: #d4d4d4;
  width: 100%;
  color: #adadad;
  font-size: 18pt;
  font-weight: bold;
  text-align: center;
  padding: 5px;
  cursor: pointer; }
  .FormInputImagePlaceholder:hover {
    background-color: #f1f1f1; }

.Loader {
  margin-bottom: 140px !important; }

.LoaderScreen i {
  margin-top: 50%;
  font-size: 40pt; }

.LoaderScreen p {
  font-size: 20pt;
  margin-top: 25px; }

@keyframes fa-anim-blink {
  0% {
    transform: rotate(0deg); }
  50% {
    transform: rotate(0deg); }
  55% {
    transform: rotate(10deg); }
  60% {
    transform: rotate(-10deg); }
  70% {
    transform: rotate(5deg); }
  80% {
    transform: rotate(-5deg); }
  90% {
    transform: rotate(0deg); } }

.fa-anim-blink {
  animation-name: fa-anim-blink;
  animation-duration: 2s;
  animation-direction: normal;
  animation-iteration-count: infinite;
  transform: rotate(0deg);
  display: block;
  float: left;
  height: 2em; }

.label-primary, .badge-primary {
  background-color: #51b775 !important; }

.UserEditContactsRow_Label {
  font-weight: bold;
  margin-bottom: 10px; }

.ibox-title h5 {
  color: #a72a2a; }

.ShippingAddress {
  min-height: 15em; }

.OrdersTable tbody tr:hover {
  cursor: pointer;
  background-color: #e2e2e2 !important; }

/** ORDER CREATION / QUOTAIONS  ***/
.OptionColorFormDialog {
  width: 100%;
  min-height: 200px;
  /* background-color: red; */
  padding: 10px;
  z-index: 666;
  left: 0;
  max-height: 350px;
  overflow-y: scroll;
  position: absolute;
  background-color: white;
  width: 560px;
  /*right: 0;*/
  border: 1px solid #c5c5c5;
  box-shadow: 7px 10px 33px;
  top: 40px; }
  .OptionColorFormDialog .OptionColorFormDialogArea {
    padding: 10px;
    border-bottom: 1px solid #e6353521; }
    .OptionColorFormDialog .OptionColorFormDialogArea h3 {
      color: #e63535;
      margin-left: -5px; }

/*********************************/
/** SALESFORCE CHAT *******/
.prechatUI .inputSplitName:nth-child(1) {
  width: calc(100% - 12px); }

.prechatUI .inputSplitName:nth-child(2) {
  display: none; }

/*********************************/
